import Link from "next/link";
import React, { Fragment } from "react";
import styled from "styled-components";

import ChevronDownWhite from "@/components/icons/ChevronDownWhite/ChevronDownWhite";
import ChevronUpWhite from "@/components/icons/ChevronUpWhite/ChevronUpWhite";
import { MenuItem } from "@/components/layout/Header/Header";
import HeaderSubmenu from "@/components/layout/Header/HeaderSubmenu/HeaderSubmenu";
import { P } from "@/components/typography";

type Props = {
  selected?: MenuItem;
  menuCols: MenuItem[];
  onChange: (x: MenuItem) => void;
  isForAdults: boolean;
};

const StyledNav = styled.nav`
  text-align: center;

  ul {
    display: flex;
    align-items: center;

    li {
      position: relative;
      // min-width: 120px;
      // max-width: 120px;
      display: flex;
      align-items: center;
      margin-right: 32px;
      cursor: pointer;

      svg {
        // position: absolute;
        // right: 0;
      }

      p {
        margin-right: 8px;
      }

      &.selected {
        font-weight: 700;

        p {
          color: black;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    ul {
      li {
        margin-right: 44px;
      }
    }
  }

  @media ${({ theme }) => theme.devices.laptop} {
  }
`;

//If they want the donate button, line 72 can be commented in
export default function HeaderNav({
  selected,
  onChange,
  menuCols,
  isForAdults,
}: Props) {
  return !isForAdults ? (
    <StyledNav>
      <ul>
        {menuCols.map((menu) => (
          <Fragment key={menu.id}>
            <li>
              {menu.children.map((child) => {
                return (
                  <div
                    key={child.name}
                    className="flex w-full items-center justify-center gap-8"
                  >
                    {child.children
                      .filter((grandchild) => grandchild.status === "published")
                      .map((grandchild) => {
                        if (grandchild.name === "Doneer nu") {
                          return (
                            <div
                              className="rounded-[8px] bg-teal px-1"
                              key={"donate-button"}
                            >
                              <Link href={grandchild.link}>
                                <P className="m-[6px] text-white">
                                  {grandchild.name}
                                </P>
                              </Link>
                            </div>
                          );
                        }

                        return (
                          <Link key={grandchild.name} href={grandchild.link}>
                            {grandchild.name
                              .split("<br>")
                              .map((part, index) => (
                                <p key={index} className={"inline-block"}>
                                  {part}
                                </p>
                              ))}
                          </Link>
                        );
                      })}
                  </div>
                );
              })}
            </li>
            <>
              {selected?.id === menu.id && selected.isForAdults && (
                <HeaderSubmenu
                  selected={selected?.name}
                  categories={selected?.children}
                />
              )}
            </>
          </Fragment>
        ))}
      </ul>
    </StyledNav>
  ) : (
    <StyledNav>
      <ul>
        {menuCols.map((menu) => (
          <Fragment key={menu.id}>
            {menu.children.length > 0 ? (
              <li
                // className={selected?.id === menu.id ? "selected" : ""}
                onClick={() => onChange(menu)}
              >
                <p className={"font-fjalla-one uppercase text-white"}>
                  {menu.name}
                </p>{" "}
                <div className="mr-3">
                  {selected?.id === menu.id ? (
                    <ChevronUpWhite />
                  ) : (
                    <ChevronDownWhite />
                  )}
                </div>
                {selected?.id === menu.id && (
                  <HeaderSubmenu
                    selected={selected?.name}
                    categories={selected?.children}
                    isForAdults
                  />
                )}
              </li>
            ) : (
              <li onClick={() => onChange(menu)}>
                {menu.name === "Doneer nu" ? (
                  <div
                    className="rounded-[8px] border border-white px-1 text-white"
                    key={"donate-button"}
                  >
                    <Link href={menu.url}>
                      <p className="mx-1 py-1 font-fjalla-one uppercase">
                        {menu.name}
                      </p>
                    </Link>
                  </div>
                ) : (
                  <Link href={menu.url} target="_blank">
                    <P color={"white"}>{menu.name}</P>{" "}
                  </Link>
                )}
              </li>
            )}
          </Fragment>
        ))}
      </ul>
    </StyledNav>
  );
}
